import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    data: string;
    isBg?: boolean;
}
const MTitle: React.FC<IProps> = ({ data, isBg }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={classNames(styles['m-title'], isBg && styles.bg)}>
            <div className={styles['line-div']}>
                <div className={styles.line1} />
                <div className={styles.line2} />
                <div className={styles.line3} />
            </div>
            <div className={styles.text}>
                {data}
                <span className={styles['text-line']} />
            </div>
            <div className={styles['line-div']}>
                <span className={styles.line3} />
                <span className={styles.line2} />
                <span className={styles.line1} />
            </div>
        </div>
    );
};

MTitle.defaultProps = { isBg: false };

export default MTitle;
