import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import styles from './style.module.less';

interface IProps {
    data: { title: string; icon: string; img: string }[];
}
const MKXS: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    return (
        <div lang={currentLocale} className={styles['m-kxs']}>
            <Carousel dots={false} ref={tabRef} adaptiveHeight beforeChange={(_, to) => setActIndex(to)}>
                {data.map((item) => (
                    <div key={item.title}>
                        <div className={styles.container}>
                            <div className={styles.div}>
                                <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles.img} />
                            </div>
                            <div className={styles.drift}>
                                <img loading="lazy" src={`${file_cdn}${item.icon}`} alt="" className={styles.icon} />
                                <span dangerouslySetInnerHTML={{ __html: intl.get(item.title) || item.title }} />
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
            <div className={styles.line}>
                {data.map((item, index) => (
                    <div className={classNames(styles.dian, actIndex === index && styles.active)} key={item.title} />
                ))}
            </div>
        </div>
    );
};

MKXS.defaultProps = {};

export default MKXS;
