import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';
import MTitle from '../../Dubaiqz/MTitle';

interface IProps {
    data: { title?: string; desc?: string; img?: string; children: { title: string; desc: string[] }[] };
}
const MTerm: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-term']}>
            {data.title && (
                <div className={styles.top}>
                    <MTitle data={intl.get(data.title) || data.title} isBg />
                </div>
            )}

            {data.desc && <div className={styles.desc}>{intl.get(data.desc) || data.desc}</div>}
            {data.img && <img loading="lazy" src={`${file_cdn}${data.img}`} alt="" className={styles['img-center']} />}

            {data.children.map((item) => (
                <div className={styles.container} key={item.title}>
                    <div className={classNames(styles.title)}>
                        <span className={styles.t}>
                            {intl.get(item.title) || item.title}
                            <span className={styles.span} />
                        </span>
                    </div>
                    {item.desc.map((itemD) => (
                        <div className={styles.text} key={itemD}>
                            <img loading="lazy" src={`${file_cdn}/static/services/turkeyym/pc/arrow.svg`} alt="" className={styles.img} />
                            <img
                                loading="lazy"
                                src={`${file_cdn}/static/services/turkeyym/pc/arrow.svg`}
                                alt=""
                                className={classNames(styles.img, styles.img2)}
                            />
                            {intl.get(itemD) || itemD}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

MTerm.defaultProps = {};

export default MTerm;
