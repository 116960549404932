import classNames from 'classnames';
import React, { ReactNode } from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    type?: 'justify' | 'last-center';
    children?: ReactNode;
    style?: any;
    isBg?: boolean;
    shadow?: boolean;
    isRMBg?: boolean;
}
const MText: React.FC<IProps> = ({ type, children, style, isBg, shadow, isRMBg }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div
            lang={currentLocale}
            className={classNames(styles['m-text'], type === 'last-center' && styles['last-center'], isBg && styles['min-height'])}
            style={{ ...style }}
        >
            <div className={styles['bg-img-box']}>
                {isBg && <img loading="lazy" src={`${file_cdn}/static/services/singaporeqz/m/arrow.svg`} alt="" className={styles['bg-img']} />}
                {isRMBg && <img loading="lazy" src={`${file_cdn}/static/services/singaporeqz/m/arrow.svg`} alt="" className={styles['rm-bg-img']} />}
            </div>
            <div className={classNames(shadow && styles['shadow-bg'])}>{children}</div>
        </div>
    );
};

MText.defaultProps = {
    type: 'justify',
    children: null,
    style: {},
    isBg: false,
    shadow: false,
    isRMBg: false
};

export default MText;
