import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    data: {
        img1: string;
        img2: string;
        children: Array<{ key: string; title: string; isYellow?: boolean }>;
    };
    type?: 'left' | 'right';
}
const Term: React.FC<IProps> = ({ data, type }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return type === 'left' ? (
        <div lang={currentLocale} className={styles.term}>
            <div className={styles['text-div']}>
                {data.children.map((item) => (
                    <div className={styles.text} key={item.key}>
                        <span />
                        {intl.get(item.title)}
                    </div>
                ))}
            </div>
            <div className={styles['img-div']}>
                <img loading="lazy" src={`${file_cdn}${data.img1}`} alt="" className={styles.img1} />
                <img loading="lazy" src={`${file_cdn}${data.img2}`} alt="" className={styles.img2} />
            </div>
        </div>
    ) : (
        <div lang={currentLocale} className={styles.term}>
            <div className={classNames(styles['img-div2'])}>
                <img loading="lazy" src={`${file_cdn}${data.img1}`} alt="" className={styles.img1} />
                <img loading="lazy" src={`${file_cdn}${data.img2}`} alt="" className={styles.img2} />
            </div>
            <div className={classNames(styles['text-div'], styles['text-div2'])}>
                {data.children.map((item) => (
                    <div className={classNames(styles.text, item.isYellow && styles['text-yellow'])} key={item.key}>
                        <span />
                        {intl.get(item.title)}
                    </div>
                ))}
            </div>
        </div>
    );
};

Term.defaultProps = {
    type: 'left'
};

export default Term;
