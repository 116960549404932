import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import MTitlecom from '../MTitlecom';
import styles from './style.module.less';

interface IProps {
    type: 'center' | 'left' | 'right';
    data: {
        title: string;
        titleDesc: string;
        children: Array<{ title: string; desc: string; img: string }>;
    };
    typeStyle?: 'square' | undefined;
    style?: any;
}
const MWhy: React.FC<IProps> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const { data, type, style, typeStyle } = props;
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-why']} style={{ ...style }}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title) || data.title} descContent={data.titleDesc} type={type} />
            </div>
            {data.children.map((item) => (
                <div
                    className={classNames(styles.container, styles[`custom-${typeStyle}`])}
                    key={item.title}
                    style={{ background: `url(${file_cdn}${item.img}) center no-repeat`, backgroundSize: 'cover' }}
                >
                    <div className={classNames(styles.left, styles[`left-${typeStyle}`])}>
                        <div className={styles.tit}>{intl.get(item.title) || item.title}</div>
                        <div className={styles.desc}>{intl.get(item.desc) || item.desc}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

MWhy.defaultProps = {
    style: {},
    typeStyle: undefined
};
export default MWhy;
