import React from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import styles from './style.module.less';

interface ChildrenType {
    key: string;
    title: string;
    colSpan?: boolean; // 是否合并列
    column2: any[];
    column3: any[];
}

interface IProps {
    data: {
        thTitle1: string;
        thTitle2: string;
        thTitleSub2: string;
        children: ChildrenType[];
    };
}

const TableCustom: React.FC<IProps> = ({ data }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['table-custom']}>
            <div className={styles['row-title']}>
                <div className={classNames(styles.cloum, styles['border-right'])}>
                    <div className={styles['cloum-sup']}>{intl.get(data.thTitle1)}</div>
                </div>
                <div className={classNames(styles.cloum, styles.cloum2)}>
                    <div className={styles['cloum-sup']}>{intl.get(data.thTitle2)}</div>
                    <div className={styles['cloum-sub']}>{intl.get(data.thTitleSub2)}</div>
                </div>
            </div>

            {data.children.map((item) => (
                <div className={classNames(styles['row-content'])} key={item.key}>
                    <div className={classNames(styles['cloum-content'], item.colSpan && styles['cloum-col-span'])}>
                        <div className={styles.content1}>{intl.get(item.title)}</div>
                        <div className={styles.content2}>
                            <div className={styles.container}>
                                {item.column2.map((item2) => (
                                    <div className={styles.row} key={item2}>
                                        <span className={styles.spot} />
                                        <span className={styles.text}>{intl.get(item2)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {!item.colSpan && (
                        <div className={classNames(styles['cloum-content'], styles['cloum-content2'])}>
                            <div className={styles.container}>
                                {item.column3.map((item3) => (
                                    <div className={styles.row} key={item3}>
                                        <span className={styles.spot} />
                                        <span className={styles.text}>{intl.get(item3)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TableCustom;
