import classnames from 'classnames';
import React from 'react';
import style from './index.module.less';

interface IProps {
    list: any[];
    itemClassName?: string;
}

const BankList: React.FC<IProps> = ({ list, itemClassName }: IProps) => {
    const file_cdn = '/oss-service';
    return (
        <div className={style.container}>
            <div className={style['card-list']}>
                {list.map((item) => (
                    <div className={classnames(style['card-item'], itemClassName)} key={item.img}>
                        <img loading="lazy" className={style['item-img']} src={`${file_cdn}${item.img}`} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
};

BankList.defaultProps = {
    itemClassName: ''
};

export default BankList;
