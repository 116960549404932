import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import style from './index.module.less';

interface IProps {
    data: Array<{ key: string; title: string; desc: string; img: string }>;
}

const MServiceAdvantages: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const [AdvantageGuaranteeList] = useState<any[]>(data);

    return (
        <div className={style.container}>
            <div className={style['container-bg']}>
                <div className={style['content-right']}>
                    <Carousel className="m-advantage-guarantee-carousel">
                        {AdvantageGuaranteeList.map((item) => (
                            <div key={item.title}>
                                <div className={style['card-item']}>
                                    <div className={style['item-content']}>
                                        <div className={style['child-item-img']}>
                                            <div className={style['title-box']} dangerouslySetInnerHTML={{ __html: intl.get(item.title) }} />
                                            <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" />
                                        </div>
                                        <div className={style.desc}>{intl.get(item.desc)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default MServiceAdvantages;
