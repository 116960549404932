import React from 'react';
import intl from 'react-intl-universal';
import MTitlecom from '../MTitlecom';
import styles from './style.module.less';

interface IProps {
    type: 'center' | 'left' | 'right';
    data: {
        img?: string;
        desc?: string;
        title: string;
        titleDesc: string;
    };
    style?: any;
}
const MWhyRegister: React.FC<IProps> = ({ data, type, style }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-provide']} style={{ ...style }}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title)} descContent={data.titleDesc} type={type} />
            </div>

            {data.img && data.desc && (
                <div className={styles['bg-img']}>
                    <div className={styles['div-img']}>
                        <img loading="lazy" src={`${file_cdn}${data.img}`} alt="" className={styles['img-a']} />
                    </div>
                    <div className={styles['div-text']}>{intl.get(data.desc)}</div>
                </div>
            )}
        </div>
    );
};

MWhyRegister.defaultProps = {
    style: {}
};

export default MWhyRegister;
