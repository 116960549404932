import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    data: Array<{ children: Array<{ img: string; title: string; content: string }> }>;
    itemStyle?: any;
}
const Advantages: React.FC<IProps> = ({ data, itemStyle }: IProps) => {
    const file_cdn = '/oss-service';

    return (
        <div className={styles.advantages}>
            {data.map((item, index) => (
                <div className={styles['finovy-row']} key={item?.children[index].title}>
                    {item.children.map((childrenItem) => (
                        <div className={styles.container} key={childrenItem.title} style={{ ...itemStyle }}>
                            <img loading="lazy" src={`${file_cdn}${childrenItem.img}`} alt="" className={styles.img} />
                            <div className={styles.right}>
                                <div className={styles.title}>{intl.get(childrenItem.title) || childrenItem.title}</div>
                                <div className={styles.content}>{intl.get(childrenItem.content) || childrenItem.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

Advantages.defaultProps = {
    itemStyle: {}
};

export default Advantages;
