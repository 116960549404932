import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';
import MTitle from '../MTitle';

interface IProps {
    data: { title: string; desc: string[] };
}
const MCareful: React.FC<IProps> = ({ data }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-careful']}>
            <div className={styles.top}>
                <MTitle data={intl.get(data.title)} />
            </div>
            {data.desc.map((item, index) => (
                <div className={classNames(index !== 0 && styles.distance, data.desc.length === index + 1 && styles['distance-m'])}>
                    <div className={styles.title}>
                        <span className={styles.t}>
                            {index < 9 ? `0${index + 1}` : index + 1}
                            <span className={styles.span} />
                        </span>
                    </div>
                    <div className={styles.container} key={item}>
                        {intl.get(item)}
                    </div>
                </div>
            ))}
        </div>
    );
};

MCareful.defaultProps = {};

export default MCareful;
