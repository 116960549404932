import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import classnames from 'classnames';
import styles from './style.module.less';
import LayoutCenter from '../../Finance/LayoutCenter';
import StepsCom from '../../../Common/StepsCom';

interface IProps {
    data: Array<{ key: string; title: string; childrenData: Array<{ title: string; desc: string }> }>;
    itemStyle?: any;
}
const CarouselCut: React.FC<IProps> = ({ data, itemStyle }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    return (
        <div lang={currentLocale} className={styles['carousel-cut']}>
            <div className={styles.cut}>
                {data.map((item, index) => (
                    <div
                        key={item.title}
                        style={{ ...itemStyle }}
                        className={classnames(styles.pot, actIndex === index && styles['pot-active'])}
                        onClick={() => {
                            tabRef.current && tabRef.current.goTo(index);
                            setActIndex(index);
                        }}
                    >
                        <img
                            loading="lazy"
                            src={`${file_cdn}/static/services/turkeyym/pc/${actIndex === index ? 'process-active' : 'process'}.png`}
                            alt=""
                        />
                        <div className={styles.title}>{intl.get(item.title)}</div>
                        <div className={classnames(styles.line, actIndex === index && styles['line-active'])} />
                    </div>
                ))}
            </div>
            <Carousel dots={false} ref={tabRef} adaptiveHeight>
                {data.map((item) => (
                    <LayoutCenter style={{ margin: '56px auto 151px' }} key={item.key}>
                        <div style={{ maxWidth: 1600, padding: '0 20px', margin: 'auto' }}>
                            <StepsCom list={item.childrenData}> </StepsCom>
                        </div>
                    </LayoutCenter>
                ))}
            </Carousel>
        </div>
    );
};

CarouselCut.defaultProps = {
    itemStyle: {}
};

export default CarouselCut;
