import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import styles from './style.module.less';
import MTitle from '../MTitle';

interface IProps {
    data: { key: string; title: string; title2: string; desc2: string[]; title3: string; desc3: string[] }[];
}
const MProcedures: React.FC<IProps> = ({ data }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    return (
        <div lang={currentLocale} className={styles['m-procedures']}>
            <Carousel dots={false} ref={tabRef} adaptiveHeight beforeChange={(_, to) => setActIndex(to)}>
                {data.map((item) => (
                    <div key={item.key}>
                        <div className={styles.top}>
                            <MTitle data={intl.get(item.title)} />
                        </div>
                        <div className={styles.title}>
                            <span className={styles.t}>
                                {intl.get(item.title2)}
                                <span className={styles.span} />
                            </span>
                        </div>
                        {item.desc2.map((itemD2) => (
                            <div className={styles.container} key={itemD2}>
                                <div className={styles['div-android']}>
                                    <div className={styles.div} />
                                </div>
                                {intl.get(itemD2)}
                            </div>
                        ))}

                        <div className={classNames(styles.title, styles.distance)}>
                            <span className={styles.t}>
                                {intl.get(item.title3)}
                                <span className={styles.span} />
                            </span>
                        </div>
                        {item.desc3.map((itemD3) => (
                            <div className={styles.container} key={itemD3}>
                                <div className={styles['div-android']}>
                                    <div className={styles.div} />
                                </div>
                                {intl.get(itemD3)}
                            </div>
                        ))}
                        <div className={styles.dian}>
                            <div className={classNames(styles.acircle, actIndex === 0 && styles.active)} />
                            <div className={classNames(styles.acircle, actIndex === 1 && styles.active)} />
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

MProcedures.defaultProps = {};

export default MProcedures;
