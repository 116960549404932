import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import MTitlecom from '../../MTitlecom';
import styles from './style.module.less';

interface IProps {
    type?: 'center' | 'left' | 'right';
    data: {
        title: string;
        desc: string;
        children: {
            title: string;
            desc: string;
        }[];
    };
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    stepType?: 'line' | 'ver';
}
const MBL: React.FC<IProps> = ({ data, type, style, titleStyle, stepType }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-bl']} style={{ ...style }}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title) || data.title} descContent={data.desc} type={type} titleStyle={{ ...titleStyle }} />
            </div>
            <div className={styles['container-line']}>
                <span className={styles.line} />
                {data.children.map((item, index) => (
                    <div className={styles.container} key={item.title}>
                        <div className={styles.left}>
                            <div className={styles.yuan} />
                        </div>
                        <div className={styles.right}>
                            <div className={classnames(styles['right-title'], stepType === 'line' ? styles['left-flex'] : null)}>
                                <span className={styles['num-title']}>{index < 9 ? `0${index + 1}` : index + 1}</span>
                                <span>{intl.get(item.title) || item.title}</span>
                            </div>
                            <div className={styles.desc} dangerouslySetInnerHTML={{ __html: intl.get(item.desc) || item.desc }} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

MBL.defaultProps = {
    type: 'center',
    style: {},
    stepType: undefined,
    titleStyle: {}
};

export default MBL;
