import React from 'react';
import intl from 'react-intl-universal';
import MTitlecom from '../MTitlecom';
import styles from './style.module.less';

interface IProps {
    type: 'center' | 'left' | 'right';
    data: {
        title: string;
        titleDesc: string;
        children: Array<{ title: string; desc: string[]; img: string }>;
    };
    style?: any;
}
const MHandling: React.FC<IProps> = ({ data, type, style }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-handling']} style={{ ...style }}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title)} descContent={data.titleDesc} type={type} />
            </div>
            <div className={styles['container-line']}>
                <span className={styles.line} />
                {data.children.map((item, index) => (
                    <div className={styles.container} key={item.title}>
                        <div className={styles.left}>
                            <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles.img} />
                            {index + 1 !== data.children.length && (
                                <img loading="lazy" src={`${file_cdn}/static/services/accounting/m/icon/arrow.svg`} alt="" className={styles.arrow} />
                            )}
                        </div>
                        <div className={styles.right}>
                            <div className={styles.tit}>
                                <span>{`0${index + 1}`}</span>
                                {intl.get(item.title)}
                            </div>
                            {item.desc.map((dItem) => (
                                <div className={styles.desc} key={dItem}>
                                    {intl.get(dItem)}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

MHandling.defaultProps = {
    style: {}
};

export default MHandling;
