import MNewBanner from './MNewBanner';
import MTitlecom from './MTitlecom';
import MText from './MText';
import MProvide from './MProvide';
import MWhy from './MWhy';
import MHandling from './MHandling';
import MDescContent from './MDescContent';
import MProcedures from './Dubaiqz/MProcedures';
import MCareful from './Dubaiqz/MCareful';
import MDubai from './Dubaiqz/MDubai';
import MTerm from './Singaporeqz/MTerm';
import MStuff from './Singaporeqz/MStuff';
import MKXS from './Thailandqz/MKXS';
import MBL from './Thailandqz/MBL';
import MPurchase from './Turkeyym/MPurchase';
import MMainApply from './Turkeyym/MMainApply';
import MCarouselCut from './Turkeyym/MCarouselCut';
import MZApply from './Mauritiusqz/MZApply';
import MHMTabs from './Hongkongym/MHMTabs';
import MWhyRegister from './MWhyRegister';
import FinovyAdvantage from './FinovyAdvantage';
import MPrecautions from './MPrecautions';
import MAllServices from './MAllServices';
import MSetUpAccount from './MSetUpAccount';

export {
    MNewBanner,
    MTitlecom,
    MText,
    MProvide,
    MWhy,
    MHandling,
    MDescContent,
    MProcedures,
    MCareful,
    MDubai,
    MTerm,
    MStuff,
    MKXS,
    MBL,
    MPurchase,
    MMainApply,
    MCarouselCut,
    MZApply,
    MHMTabs,
    MWhyRegister,
    FinovyAdvantage,
    MAllServices,
    MPrecautions,
    MSetUpAccount
};
