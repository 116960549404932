import React, { ReactNode } from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    children?: ReactNode;
    childrenStyle?: any;
    imgStyle?: any;
}
const RightSuperIcon: React.FC<IProps> = ({ children, childrenStyle, imgStyle }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['right-super-icon']} style={{ ...childrenStyle }}>
            <img
                loading="lazy"
                src={`${file_cdn}/static/services/turkeyym/pc/right.svg`}
                alt=""
                className={styles['project-img']}
                style={{ ...imgStyle }}
            />
            {children}
        </div>
    );
};

RightSuperIcon.defaultProps = {
    children: null,
    childrenStyle: {},
    imgStyle: {}
};

export default RightSuperIcon;
