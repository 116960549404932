import React from 'react';
import intl from 'react-intl-universal';
import styles from './index.module.less';

interface Iprops {
    title: string;
    list: { id: string; img: string; title: string }[];
    isLine?: boolean;
}

const MTypeFile: React.FC<any> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { title, list, isLine } = props;
    const typeFileStyles = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--public-bg': `url('${file_cdn}/static/services/chinanotary/m/title.svg') no-repeat center`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--container-bg': `url('${file_cdn}/static/services/chinanotary/m/file-bg.png') no-repeat center`
    };

    return (
        <div className={styles['m-type-file']} style={typeFileStyles as React.CSSProperties}>
            <div className={styles.title}>{title}</div>
            <div className={styles.desc}>
                {list.map((item) => (
                    <div className={styles.container} key={item.id}>
                        <div dangerouslySetInnerHTML={{ __html: intl.get(item.title) }} className={styles.text} />
                        <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles['s-img']} />
                    </div>
                ))}
            </div>
            {isLine && <div className={styles.line} />}
        </div>
    );
};

MTypeFile.defaultProps = {
    isLine: false
};

export default MTypeFile;
