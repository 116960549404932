import React, { ReactNode } from 'react';
import styles from './style.module.less';

interface IProps {
    children?: ReactNode;
    style?: any;
}
const LayoutCenter: React.FC<IProps> = ({ children, style }: IProps) => (
    <div className={styles['layout-center']} style={{ ...style }}>
        {children}
    </div>
);

LayoutCenter.defaultProps = {
    children: null,
    style: {}
};

export default LayoutCenter;
