import React, { useState } from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import style from './index.module.less';

interface Iprops {
    termList: any[];
    datumList?: any[];
    completeDatumList: any[];
    datumListTh?: any[];
    datumListFor?: any[];
    twoItemTitClassName?: string;
    twoItemDesClassName?: string;
    twoItemChildClassName?: string;
}
const MPrecautions: React.FC<Iprops> = (props: Iprops) => {
    const { termList, datumList, completeDatumList, datumListTh, datumListFor, twoItemTitClassName, twoItemDesClassName, twoItemChildClassName } =
        props;
    const [list] = useState([
        {
            title: intl.get('HongKong.PRTab1')
        },
        {
            title: intl.get('HongKong.PRTab2')
        },
        {
            title: intl.get('Dubai.PRTab3')
        }
    ]);
    const [actNum, setActNum] = useState<number>(0);

    return (
        <div className={style.container}>
            <div className={style['precautions-tit']}>{intl.get('HongKong.RP')}</div>
            <div className={style['tab-list']}>
                {list.map((item, index) => (
                    <div
                        onClick={() => {
                            setActNum(index);
                        }}
                        className={classnames(style['title-item'], actNum === index ? style.act : null)}
                        key={item.title}
                    >
                        {item.title}
                    </div>
                ))}
            </div>
            <div className={style.line} />
            <div className={style['card-container']}>
                <div className={style['card-list']}>
                    {actNum === 0 && (
                        <div className={style['term-list']}>
                            {termList.map((item) => (
                                <div key={item.title} className={style['btn-content']}>
                                    <div className={style.btn}>
                                        <span /> {intl.get(item.title)}
                                    </div>
                                    <div className={style['btn-desc']} dangerouslySetInnerHTML={{ __html: intl.get(item.desc) }} />
                                </div>
                            ))}
                        </div>
                    )}
                    {actNum === 1 && (
                        <div className={style['content-flex']}>
                            <div className={style['tab-two-list']}>
                                {datumListTh &&
                                    datumListTh.map((item) => (
                                        <div key={item.id} className={style['korea-list-con']}>
                                            <div className={style['korea-title']}>{intl.get(item.title)}</div>
                                            {item.children.map((childItem) => (
                                                <div key={childItem.id} className={style['korea-child-list-con']}>
                                                    <div className={style['korea-box-btn']}>
                                                        {childItem.title && (
                                                            <div className={style['korea-three-btn']}>
                                                                {intl.get(childItem.title) || childItem.title}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {childItem.children.map((childItem2) => (
                                                        <div className={style['two-item-tit']} key={childItem2.id}>
                                                            <span className={style['child-dot']} />
                                                            <span className={style['datum-desc']}>
                                                                {intl.get(childItem2.desc) || childItem2.desc}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                {datumList &&
                                    datumList.map((item) => (
                                        <div key={item.id} className={style['three-btn-box']}>
                                            <div className={classnames(style['three-btn'], twoItemTitClassName)}>
                                                {intl.get(item.title) || item.title}
                                            </div>
                                            <div className={twoItemChildClassName}>
                                                {item.children.map((childItem) => (
                                                    <div className={style['two-item-tit']} key={childItem.id}>
                                                        <span className={classnames(style['child-dot'], twoItemDesClassName)} />
                                                        <span className={style['datum-desc']}>{intl.get(childItem.desc) || childItem.desc}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                {datumListFor && (
                                    <div className={style['term-list']}>
                                        {datumListFor.map((item) => (
                                            <div key={item.id} className={style['three-btn-content']}>
                                                <span className={style['child-dot']} />
                                                <div className={style['datum-desc']}>{intl.get(item.title) || item.title}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {actNum === 2 && (
                        <div className={style['term-list']}>
                            <div className={style['three-two-list']}>
                                {completeDatumList.map((item) => (
                                    <div key={item.title} className={style['three-btn-content']}>
                                        <span className={style['child-dot']} />
                                        <div className={style['datum-desc']}>{intl.get(item.title) || item.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
MPrecautions.defaultProps = {
    datumListTh: undefined,
    datumList: undefined,
    datumListFor: undefined,
    twoItemTitClassName: '',
    twoItemDesClassName: '',
    twoItemChildClassName: ''
};
export default MPrecautions;
