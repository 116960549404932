import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Carousel } from 'antd';
import styles from './style.module.less';

interface IProps {
    data: Array<{ key: string; title: string; leftIcon: string; rightIcon: string; img: string }>;
}

const VisaPeriod: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const executeRef = useRef<boolean>(true);
    const tabRef = useRef<any>(null);
    const [select, setSelect] = useState<number>(0);

    const availableClick = async (index: number) => {
        if (executeRef.current) {
            executeRef.current = false;
            tabRef.current && tabRef.current.goTo(index);
            setSelect(index);
            setTimeout(() => {
                executeRef.current = true;
            }, 500);
        }
    };

    return (
        <div lang={currentLocale} className={styles['visa-period']}>
            <div className={styles.left}>
                {data.map((item, index) => (
                    <div
                        className={classnames(styles.available, select === index && styles['available-active'])}
                        key={item.title}
                        onClick={() => availableClick(index)}
                    >
                        {select === index ? (
                            <img loading="lazy" src={`${file_cdn}${item.leftIcon}`} alt="" className={styles['left-icon']} />
                        ) : (
                            <div className={styles['left-icon']} />
                        )}
                        <div className={styles.text}>{intl.get(item.title)}</div>
                        <img loading="lazy" src={`${file_cdn}${item.rightIcon}`} alt="" className={styles['right-icon']} />
                    </div>
                ))}
            </div>
            <div className={styles.right}>
                <Carousel dots={false} ref={tabRef} adaptiveHeight dotPosition="left">
                    {data.map((item) => (
                        <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" key={item.key} />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default VisaPeriod;
