import React from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import style from './index.module.less';

interface IProps {
    list: any[];
}

const SetUpAccount: React.FC<IProps> = ({ list }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;
    return (
        <div className={style.container}>
            <div className={style.line} />
            <div className={classNames(style['card-list'], style[currentLocale])}>
                {list.map((item) => (
                    <div className={classNames(style['card-item'], style[currentLocale])} key={item.title}>
                        <div className={classNames(style['tit-btn'], style[currentLocale])}>{intl.get(item.title)}</div>
                    </div>
                ))}
            </div>
            <div className={style.line} />
        </div>
    );
};

export default SetUpAccount;
