import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    data: string[];
}
const MZApply: React.FC<IProps> = ({ data }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['mz-apply']}>
            <div className={styles['container-div']}>
                {data.map((item) => (
                    <div className={classNames(styles.container)} key={item}>
                        <div className={styles['div-android']}>
                            <div className={styles.div} />
                        </div>
                        {intl.get(item) || item}
                    </div>
                ))}
            </div>
        </div>
    );
};

MZApply.defaultProps = {};

export default MZApply;
