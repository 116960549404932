import DescContent from './Finance/DescContent';
import ServiceContent from './Finance/ServiceContent';
import FinovyService from './Finance/FinovyService';
import LayoutCenter from './Finance/LayoutCenter';
import Advantages from './ImmigrationVisa/Advantages';
import ApplyCondition from './ImmigrationVisa/ApplyCondition';
import RightSuperIcon from './ImmigrationVisa/RightSuperIcon';
import CarouselCut from './ImmigrationVisa/CarouselCut';
import EliteVisa from './VisaService/EliteVisa';
import ServicesAvailable from './VisaService/ServicesAvailable';
import ProjectIntro from './Turkeyym/ProjectIntro';
import ProjectIntroduce from './Hongkongzc/ProjectIntroduce';
import ProjectAdvantage from './Hongkongzc/ProjectAdvantage';
import TableCustom from './Dubaiqz/TableCustom';
import Visa from './Dubaiqz/Visa';
import CarouselEmploymentPermit from './Singaporeqz/CarouselEmploymentPermit';
import EntrustedChina from './Chinanotary/EntrustedChina';
import MEntrustedChina from './Chinanotary/MEntrustedChina';
import TypeFile from './Chinanotary/TypeFile';
import MTypeFile from './Chinanotary/MTypeFile';
import ServiceAdvantages from './Chinanotary/ServiceAdvantages';
import MServiceAdvantages from './Chinanotary/MServiceAdvantages';
import BankList from './HongKong/bankList';
import SetUpAccount from './HongKong/setUpAccount';

export {
    DescContent,
    ServiceContent,
    FinovyService,
    LayoutCenter,
    Advantages,
    ApplyCondition,
    RightSuperIcon,
    CarouselCut,
    EliteVisa,
    ServicesAvailable,
    ProjectIntro,
    ProjectIntroduce,
    ProjectAdvantage,
    TableCustom,
    Visa,
    CarouselEmploymentPermit,
    MEntrustedChina,
    EntrustedChina,
    TypeFile,
    MTypeFile,
    ServiceAdvantages,
    MServiceAdvantages,
    BankList,
    SetUpAccount
};
