import React, { ReactNode } from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    children?: ReactNode;
    style?: any;
}
const DescContent: React.FC<IProps> = ({ children, style }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles.descContent} style={{ ...style }}>
            {children}
        </div>
    );
};

DescContent.defaultProps = {
    children: null,
    style: {}
};

export default DescContent;
