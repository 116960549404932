import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import classnames from 'classnames';
import styles from './style.module.less';
import StepsCom from '../../../Common/StepsCom';
import Titlecom from '../../../Common/Titlecom';
import Term from '../Term';
import BillOfMaterials from '../BillOfMaterials';
import VisaPeriod from '../VisaPeriod';

type TermType = {
    img1: string;
    img2: string;
    children: Array<{ key: string; title: string; isYellow?: boolean }>;
};

type BillOfMaterialsType = any[];

type StepsComType = Array<{
    title: string;
    desc: string;
}>;

type AvailableType = Array<{ key: string; title: string; leftIcon: string; rightIcon: string; img: string }>;
interface IProps {
    data: {
        bgImg: string;
        title: string;
        titleDesc: string;
        content: string;
        tabs: Array<{ key: string; title: string }>;
    };
    termData: TermType;
    billOfMaterialsData: BillOfMaterialsType;
    stepsComData: StepsComType;
    availableList: AvailableType;
    itemStyle?: any;
    permitStyle?: any;
    type?: 'left' | 'right';
}
const CarouselEmploymentPermit: React.FC<IProps> = (props: IProps) => {
    const { data, itemStyle, permitStyle, type, termData, billOfMaterialsData, stepsComData, availableList } = props;
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const executeRef = useRef<boolean>(true);
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    const availableClick = async (index: number) => {
        if (executeRef.current) {
            executeRef.current = false;
            tabRef.current && tabRef.current.goTo(index);
            setActIndex(index);
            setTimeout(() => {
                executeRef.current = true;
            }, 700);
        }
    };

    return (
        <div lang={currentLocale} className={styles['carousel-employment-permit']}>
            <img
                loading="lazy"
                src={`${file_cdn}${data.bgImg}`}
                alt=""
                className={classnames(styles['carousel-img'], type === 'left' ? styles['img-right'] : styles['img-left'])}
            />
            <div className={styles['employment-permit']} style={{ ...permitStyle }}>
                <Titlecom title={intl.get(data.title)} descContent={data.titleDesc} type={type} />
                <div className={classnames(styles.abbreviation, type === 'right' && styles['abbreviation-right'])}>{intl.get(data.content)}</div>
                <div className={styles.cut}>
                    {data.tabs.map((item, index) => (
                        <div
                            key={item.title}
                            style={{ ...itemStyle }}
                            className={classnames(styles.pot, actIndex === index && styles['pot-active'])}
                            onClick={() => availableClick(index)}
                        >
                            <div className={classnames(styles.bg, actIndex === index && styles['bg-active'])} />
                            <img
                                loading="lazy"
                                src={`${file_cdn}/static/services/singaporeqz/pc/${actIndex === index ? 'process-active' : 'process'}.svg`}
                                alt=""
                                className={styles.img}
                            />
                            <div className={styles.title}>{intl.get(item.title)}</div>
                            <div className={classnames(styles.line, actIndex === index && styles['line-active'])} />
                        </div>
                    ))}
                </div>
                <Carousel
                    dots={false}
                    ref={tabRef}
                    adaptiveHeight
                    className={classnames(
                        styles['carousel-bill'],
                        actIndex === 0 && styles.bill455,
                        actIndex === 1 && styles.bill390,
                        actIndex === 1 && styles['bill-shadow'],
                        actIndex === 2 && styles.bill245,
                        actIndex === 3 && styles.bill560
                    )}
                >
                    <div>
                        <Term data={termData} type={type} />
                    </div>
                    <div>
                        <BillOfMaterials data={billOfMaterialsData} />
                    </div>
                    <div>
                        <StepsCom list={stepsComData} />
                    </div>
                    <div>
                        <VisaPeriod data={availableList} />
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

CarouselEmploymentPermit.defaultProps = {
    itemStyle: {},
    permitStyle: {},
    type: 'left'
};

export default CarouselEmploymentPermit;
