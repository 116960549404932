import React from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import styles from './style.module.less';
import Titlecom from '../../../Common/Titlecom';
import RightSuperIcon from '../../ImmigrationVisa/RightSuperIcon';

interface IProps {
    data: {
        title: string;
        desc: string;
        isRightSuperIcon?: boolean;
        content: any[];
        children: Array<{ title: string; img: string; content: string; textArr: any[] }>;
    };
    childrenStyle?: any;
}
const EliteVisa: React.FC<IProps> = ({ data, childrenStyle }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['container-elite-visa']}>
            {data?.isRightSuperIcon && <RightSuperIcon imgStyle={{ top: -120 }} />}
            <div className={styles['elite-visa']} style={{ ...childrenStyle }}>
                <Titlecom title={intl.get(data.title) || data.title} descContent={data.desc} />
                <div className={styles.visa} style={{ background: `url('${file_cdn}/static/services/thailandqz/pc/bg-diwen.png')` }}>
                    {data.content.map((item, index) => (
                        <div className={classNames(styles.content, data.content.length === index + 1 && styles['content-last'])} key={item}>
                            {intl.get(item) || item}
                        </div>
                    ))}
                    {data.children.map((item, index) =>
                        index % 2 === 0 ? (
                            <div className={styles.container} key={item.title}>
                                <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles.img} />
                                <div className={styles.condition}>
                                    <div className={styles.text}>
                                        <div className={styles.title}>{intl.get(item.title) || item.title}</div>
                                        {item.content && <div className={styles.content}>{intl.get(item.content) || item.content}</div>}
                                        {item.textArr.map((tItem, tIndex) => (
                                            <div className={styles['serial-no']} key={tItem}>
                                                <span>{tIndex + 1 < 10 ? `0${tIndex + 1}` : tIndex}</span>
                                                {intl.get(tItem) || tItem}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.container} key={item.title}>
                                <div className={styles.condition}>
                                    <div className={styles.text}>
                                        <div className={styles.title}>{intl.get(item.title) || item.title}</div>
                                        {item.content && <div className={styles.content}>{intl.get(item.content) || item.content}</div>}
                                        {item.textArr.map((tItem, tIndex) => (
                                            <div className={styles['serial-no']} key={tItem}>
                                                <span>{tIndex + 1 < 10 ? `0${tIndex + 1}` : tIndex}</span>
                                                {intl.get(tItem) || tItem}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles.img} />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

EliteVisa.defaultProps = {
    childrenStyle: {}
};

export default EliteVisa;
