import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import styles from './style.module.less';
import Titlecom from '../../../Common/Titlecom';

interface IProps {
    data: {
        img1: string;
        img2: string;
        title: string;
        desc: string;
        arr: Array<{ title: string; children: any[] }>;
    };
}
const ApplyCondition: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['apply-condition']}>
            <img loading="lazy" src={`${file_cdn}/static/services/turkeyym/pc/超级符号.svg`} alt="" className={styles.img} />
            <div className={styles.container}>
                <div className={styles.left}>
                    <img loading="lazy" src={`${file_cdn}${data.img1}`} alt="" className={styles['img-bottom']} />
                    <img loading="lazy" src={`${file_cdn}${data.img2}`} alt="" className={styles['img-top']} />
                </div>
                <div className={styles.right}>
                    <Titlecom title={intl.get(data.title) || data.title} descContent={data.desc} />
                    {data.arr.map((item, index) => (
                        <div key={item.title} className={classnames(styles['item-applicant'], index === 0 && styles.item8)}>
                            <div className={styles.applicant}>
                                <div className={styles.span} />
                                <div className={styles.text}>{intl.get(item.title) || item.title}</div>
                            </div>
                            <div className={styles['applicant-content']}>
                                {item.children.map((cItem) => (
                                    <div className={styles.item} key={cItem}>
                                        <div>
                                            <img
                                                loading="lazy"
                                                className={styles['item-img']}
                                                src={`${file_cdn}/static/services/audit/pc/箭头.svg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles['item-text']}>{intl.get(cItem) || cItem}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

ApplyCondition.defaultProps = {};

export default ApplyCondition;
