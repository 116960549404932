import React from 'react';
import intl from 'react-intl-universal';
import { removeBrTags } from '@utils/utils';
import style from './index.module.less';

interface IProps {
    isMobile?: boolean;
    advantageList: any[];
}

const ProjectAdvantage: React.FC<IProps> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const { advantageList, isMobile } = props;
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <>
            {isMobile ? (
                <div className={style['m-container']} style={{ backgroundImage: `url('${file_cdn}/static/services/hongkongzc/m/advantage-bg.png')` }}>
                    {advantageList.map((item) => (
                        <div key={item.title} className={style['list-item']}>
                            <div className={style.header}>
                                <em className={style['left-ball']} />
                                <span className={style.title}>{intl.get(item.title)}</span>
                            </div>
                            <div className={style.desc} dangerouslySetInnerHTML={{ __html: removeBrTags(intl.get(item.desc) || item.desc) }} />
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    className={currentLocale === 'en_US' ? style['en-container'] : style.container}
                    style={{ backgroundImage: `url('${file_cdn}/static/services/hongkongzc/pc/advantage-bg.png')` }}
                >
                    {advantageList.map((item) => (
                        <div key={item.title} className={style['list-item']}>
                            <div className={style.header}>
                                <em className={style['left-ball']} />
                                <span className={style.title}>{intl.get(item.title) || item.title}</span>
                            </div>
                            <div className={style.desc} dangerouslySetInnerHTML={{ __html: intl.get(item.desc) || item.desc }} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

ProjectAdvantage.defaultProps = {
    isMobile: false
};

export default ProjectAdvantage;
