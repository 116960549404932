import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';
import MTitle from '../../Dubaiqz/MTitle';

interface IProps {
    data: { title: string; children: string[] };
    isLine?: boolean;
}
const MStuff: React.FC<IProps> = ({ data, isLine }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-term']}>
            <MTitle data={intl.get(data.title)} isBg />

            <div className={styles['container-div']}>
                {isLine && (
                    <span
                        className={styles.line}
                        style={{ background: `url('${file_cdn}/static/services/singaporeqz/m/虚线.svg') repeat-y center` }}
                    />
                )}
                {data.children.map((item) => (
                    <div className={classNames(styles.container, isLine && styles['container-m'])} key={item}>
                        <div className={styles['div-android']}>
                            <div className={styles.div} />
                        </div>
                        {intl.get(item)}
                    </div>
                ))}
            </div>
        </div>
    );
};

MStuff.defaultProps = { isLine: false };

export default MStuff;
