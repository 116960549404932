import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import { LayoutCenter } from '@components/Services';
import { MTitlecom } from '@components/MCommon';
import style from './index.module.less';

interface Iprops {
    title: string;
    titleDesc: string;
    label: string;
    dataList: { title: string; desc: string; img: string }[];
}

const MAllServices: React.FC<any> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { title, titleDesc, dataList, label } = props;
    const [actIndex, setActIndex] = useState<number>(0);
    return (
        <>
            <LayoutCenter style={{ margin: '72px auto 22px' }}>
                <MTitlecom title={title} descContent={titleDesc} label={label} type="center" />
            </LayoutCenter>
            <div className={style.container}>
                <div className={style['container-bg']}>
                    <div className={style['content-right']}>
                        <Carousel
                            dots={false}
                            centerMode
                            centerPadding="60px"
                            beforeChange={(oldIdx, newIdex) => {
                                const index = Math.ceil(newIdex);
                                setActIndex(index);
                            }}
                            slidesToShow={1}
                        >
                            {dataList.map((item, index) => (
                                <div key={item.title}>
                                    <div className={style['card-item']}>
                                        <div className={`${style['item-content']} ${actIndex === index ? style.act : ''}`}>
                                            <div className={style['child-item-img']}>
                                                <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" />
                                            </div>
                                            {actIndex === index && <div className={style['title-box']}>{intl.get(item.title)}</div>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MAllServices;
