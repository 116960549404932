import React from 'react';
import Titlecom from '@components/Common/Titlecom';
import classNames from 'classnames';
import styles from './index.module.less';

interface Iprops {
    currentLocale?: string;
    title: string;
    titleType: 'center' | 'left';
    descContent: string;
    isDescContentBold?: boolean;
    contentEnglish: string;
    contentTitle: string;
    contentDesc1: string;
    contentDesc2: string;
}

const EntrustedChina: React.FC<any> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { title, titleType, descContent, isDescContentBold, contentEnglish, contentTitle, contentDesc1, contentDesc2, currentLocale } = props;

    return (
        <div className={styles['entrusted-china']}>
            <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/pc/supersymbols.svg`} alt="" className={styles.img} />
            <Titlecom title={title} descContent={descContent} type={titleType} isDescContentBold={isDescContentBold} />
            <div className={styles.desc}>
                <div className={styles.left}>
                    <div className={styles.text}>
                        {contentEnglish}
                        <b />
                    </div>
                    <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/pc/entrust1.jpg`} alt="" />
                    <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/pc/entrust2.jpg`} alt="" />
                </div>
                <div className={styles.right}>
                    <div className={styles.title}>{contentTitle}</div>
                    <p className={classNames(styles.p, styles[`p-${currentLocale}`])}>{contentDesc1}</p>
                    <p className={classNames(styles.p, styles[`p-${currentLocale}`])}>{contentDesc2}</p>
                </div>
            </div>
        </div>
    );
};

EntrustedChina.defaultProps = {
    currentLocale: '',
    isDescContentBold: false
};

export default EntrustedChina;
