export const phoneReg = /^1(3[0-9]|4[57]|5[0-35-9]|6[6]|7[0135678]|8[0-9]|9[89])\d{8}$/;
export const getMoblieNum = (): string[] => {
    const numArray = [
        '139',
        '138',
        '137',
        '136',
        '135',
        '134',
        '159',
        '158',
        '157',
        '150',
        '151',
        '152',
        '188',
        '187',
        '182',
        '183',
        '184',
        '178',
        '130',
        '131',
        '132',
        '156',
        '155',
        '186',
        '185',
        '176',
        '133',
        '153',
        '189',
        '180',
        '181',
        '177'
    ]; // 这是目前找到的除了数据卡外的手机卡前三位，类型是字符串数组
    const numList = []; // 创建一个数组用来存放10个手机号
    const arraryLength = numArray.length; // 获取数组长度，这样如果手机号前三位取值单位发生变化，在下一步求i的地方就不用修改随机数取值范围了
    for (let n = 0; n < 10; n++) {
        // eslint-disable-next-line radix
        const i = parseInt(`${Math.random() * arraryLength}`); // 注意乘以的是上面numArray数组的长度，这样就可以取出数组中的随机一个数。random的取值范围是大于等于0.0，小于1.0，相乘后得到的就是0到（数组长度-1）的值。
        let num = numArray[i]; // 取出随机的手机号前三位并赋值给num，手机号前三位是字符串类型的
        for (let j = 0; j < 8; j++) {
            num += Math.floor(Math.random() * 10); // num是字符串，后面的数字被当做字符串。所以变成两个字符串拼接了
        }
        numList.push(num.replace(num.substring(3, 7), '****'));
    }
    return numList; // 修改span的内容，每次都是清空修改，而不是叠加
};

/** 导出一个函数，用于移除字符串中的<br/>标签 */
export const removeBrTags = (str: string): string =>
    str
        .replace(/<br\/>/g, '')
        .replace(/<br>/g, '')
        .replace(/<br \/>/g, '');

/**
 * 获取html lang
 * @param l
 * @returns
 */
export const getHtmlLang = (l: string) => {
    // 简体中文页面：html lang=zh-cmn-Hans
    // 繁体中文页面：html lang=zh-cmn-Hant
    // 英语页面：html lang=en

    const langMts: Record<string, string> = {
        zh_CNS: 'zh-cmn-Hans',
        zh_CNT: 'zh-cmn-Hant',
        en_US: 'en'
    };

    return langMts[l] || 'en';
};
