import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './index.module.less';

interface Iprops {
    currentLocale?: string;
    title: string;
    list: { id: string; img: string; title: string }[];
    isLine?: boolean;
}

const TypeFile: React.FC<any> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { currentLocale, title, list, isLine } = props;
    const typeFileStyles = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--public-bg': `url('${file_cdn}/static/services/chinanotary/pc/business/title.svg') no-repeat center`
    };

    return (
        <div className={styles['type-file']} style={typeFileStyles as React.CSSProperties}>
            <div className={styles.title}>{title}</div>
            <div className={styles.desc}>
                {list.map((item) => (
                    <div className={styles.container} key={item.id}>
                        <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/pc/file-bg.jpg`} alt="" />
                        <div className={styles.swim}>
                            <div
                                dangerouslySetInnerHTML={{ __html: intl.get(item.title) }}
                                className={classNames(styles.text, styles[`text-${currentLocale}`], intl.get(item.title)?.length > 81 && styles.word)}
                            />
                            <div className={styles['c-img']}>
                                <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles['s-img']} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {isLine && <div className={styles.line} />}
        </div>
    );
};

TypeFile.defaultProps = {
    currentLocale: '',
    isLine: false
};

export default TypeFile;
