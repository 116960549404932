import React from 'react';
import intl from 'react-intl-universal';
import MTitlecom from '../MTitlecom';
import styles from './style.module.less';

interface IProps {
    type: 'center' | 'left' | 'right';
    data: {
        img?: string;
        desc?: string;
        title: string;
        titleDesc: string;
        children: Array<{ title: string; desc: string; img: string }>;
    };
    style?: any;
}
const MProvide: React.FC<IProps> = ({ data, type, style }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-provide']} style={{ ...style }}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title) || data.title} descContent={data.titleDesc} type={type} />
            </div>

            {data.img && data.desc && (
                <div className={styles['bg-img']}>
                    <div className={styles['div-img']}>
                        <img loading="lazy" src={`${file_cdn}${data.img}`} alt="" className={styles['img-a']} />
                    </div>
                    <div className={styles['div-text']}>{intl.get(data.desc)}</div>
                </div>
            )}

            {data.children.map((item) => (
                <div className={styles.container} key={item.title}>
                    <div className={styles.left}>
                        <div className={styles.tit}>{intl.get(item.title) || item.title}</div>
                        {item.desc && <div className={styles.desc} dangerouslySetInnerHTML={{ __html: intl.get(item.desc) || item.desc }} />}
                    </div>
                    <div className={styles.right}>
                        <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" />
                    </div>
                </div>
            ))}
        </div>
    );
};

MProvide.defaultProps = {
    style: {}
};

export default MProvide;
