import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Titlecom } from '@components/Common';
import styles from './style.module.less';

interface IProps {
    data: {
        img: string;
        title: string;
        desc: string;
        arr: Array<{ title: string; children: any[] }>;
    };
}
const Visa: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles.visa}>
            <img loading="lazy" src={`${file_cdn}/static/services/turkeyym/pc/超级符号.svg`} alt="" className={styles.img} />
            <div className={styles.container}>
                <div className={styles.left}>
                    <img loading="lazy" src={`${file_cdn}${data.img}`} alt="" className={styles['left-img']} />
                </div>
                <div className={styles.right}>
                    <Titlecom title={intl.get(data.title)} descContent={data.desc} />
                    {data.arr.map((item, index) => (
                        <div key={item.title} className={classnames(styles['item-applicant'], index === 0 && styles.item8)}>
                            <div className={styles.applicant}>
                                <div className={styles.span} />
                                <div className={styles.text}>{intl.get(item.title)}</div>
                            </div>
                            <div className={styles['applicant-content']}>
                                {item.children.map((cItem) => (
                                    <div className={styles.item} key={cItem}>
                                        <div>
                                            <img
                                                loading="lazy"
                                                className={styles['item-img1']}
                                                src={`${file_cdn}/static/services/audit/pc/箭头.svg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles['item-text']}>{intl.get(cItem)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Visa.defaultProps = {};

export default Visa;
