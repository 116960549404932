import classNames from 'classnames';
import React, { ReactNode, useRef, useState } from 'react';
import { Carousel } from 'antd';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    data?: { title: string; children: { title: string; desc: string[] }[] }[];
    contantOne?: { title: string; children: React.ReactNode };
}
const MHMTabs: React.FC<IProps> = ({ data, contantOne }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    const renderTitle = (list): ReactNode =>
        list.map((item, index) => (
            <span
                className={classNames(styles.tab, actIndex === index && styles['tab-active'])}
                onClick={() => {
                    tabRef.current && tabRef.current.goTo(index);
                    setActIndex(index);
                }}
                key={item.title}
            >
                {intl.get(item.title) || item.title}
            </span>
        ));

    return (
        <div lang={currentLocale} className={styles['m-hm-tabs']}>
            <div className={styles.container}>
                <div className={styles.cut}>{renderTitle(!data.length ? [{ title: contantOne.title }] : data)}</div>

                <Carousel dots={false} ref={tabRef} adaptiveHeight>
                    {!data.length
                        ? contantOne.children
                        : data.map((item) => (
                              <div key={item.title} className={styles['container-div']}>
                                  {item.children.map((itemC) => (
                                      <div key={itemC.title} className={styles.row}>
                                          <span className={styles['row-title']}>{intl.get(itemC.title) || itemC.title}</span>
                                          <div className={styles['row-desc']}>
                                              {itemC.desc.map((itemD) => (
                                                  <div
                                                      className={styles['row-text']}
                                                      key={itemD}
                                                      dangerouslySetInnerHTML={{ __html: intl.get(itemD) || itemD }}
                                                  />
                                              ))}
                                          </div>
                                      </div>
                                  ))}
                              </div>
                          ))}
                </Carousel>
            </div>
        </div>
    );
};

MHMTabs.defaultProps = {
    data: [],
    contantOne: { title: '', children: '' }
};

export default MHMTabs;
