import React, { useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { Carousel } from 'antd';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import styles from './index.module.less';

interface IProps {
    bgImg: string;
    maskBgColor?: string;
    descContent?: any;
    title?: any;
    type?: 'black' | 'black8' | undefined;
    isAutoHeight?: boolean;
}

const NewBanner: React.FC<IProps> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const { bgImg, maskBgColor, descContent, title, type, isAutoHeight } = props;
    // const [actIndex, setActIndex] = useState<number>(0);
    const bannerRef = useRef<any>(null);
    const bannerTextRef = useRef<any>(null);
    return (
        <div className={styles.contianer}>
            <Carousel
                autoplay
                dots={false}
                className={styles['banner-min']}
                ref={bannerRef}
                afterChange={(current) => {
                    bannerTextRef.current && bannerTextRef.current.goTo(current);
                }}
            >
                <div>
                    <div className={styles.banner} style={{ background: `url(${file_cdn}${bgImg}) no-repeat center`, backgroundSize: 'cover' }} />
                </div>
            </Carousel>
            <div className={styles['banner-decorate']}>
                <img src={`${file_cdn}/static/home/m-banner-decorate.png`} alt="" />
            </div>
            <div className={styles['banner-mask-con']}>
                <div className={classnames(styles['mask-con'], isAutoHeight ? styles['auto-wid'] : '')}>
                    <div
                        className={classnames(styles.mask, type === 'black' ? styles['mask-bai'] : styles[`mask-${type}` ?? ''])}
                        style={{ backgroundImage: maskBgColor || '' }}
                    >
                        <div className={styles['text-content']}>
                            <div
                                className={classnames(
                                    styles.title,
                                    type === 'black' ? styles['tit-black'] : styles[`tit-${type}` ?? ''],
                                    styles[currentLocale]
                                )}
                            >
                                {title}
                            </div>
                            <div
                                className={classnames(
                                    styles.desc,
                                    type === 'black' ? styles['desc-org'] : styles[`desc-${type}` ?? ''],
                                    styles[currentLocale]
                                )}
                                dangerouslySetInnerHTML={{ __html: renderToString(descContent) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
NewBanner.defaultProps = {
    maskBgColor: '',
    descContent: '',
    title: '',
    type: undefined,
    isAutoHeight: false
};
export default NewBanner;
