import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';

interface IProps {
    data: any[];
}
const BillOfMaterials: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['bill-of-materials']}>
            <img loading="lazy" src={`${file_cdn}/static/services/singaporeqz/pc/right.svg`} alt="" className={styles.img} />
            {data.map((item) => (
                <div className={styles.text} key={item}>
                    <span />
                    {intl.get(item)}
                </div>
            ))}
        </div>
    );
};

export default BillOfMaterials;
