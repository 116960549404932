import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Carousel } from 'antd';
import styles from './style.module.less';

interface IProps {
    data: Array<{ key: string; title: string; desc: string; leftIcon: string; rightIcon: string; img: string }>;
}

const ServiceAdvantages: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const executeRef = useRef<boolean>(true);
    const tabRef = useRef<any>(null);
    const [select, setSelect] = useState<number>(0);

    const availableClick = async (index: number) => {
        if (executeRef.current) {
            executeRef.current = false;
            tabRef.current && tabRef.current.goTo(index);
            setSelect(index);
            setTimeout(() => {
                executeRef.current = true;
            }, 500);
        }
    };

    return (
        <div lang={currentLocale} className={styles['service-advantages']}>
            <div className={styles['grey-block']} />
            <div className={styles.advantages}>
                <div className={styles.left}>
                    {data.map((item, index) => (
                        <div
                            className={classnames(styles.available, select === index && styles['available-active'])}
                            key={item.title}
                            onClick={() => availableClick(index)}
                        >
                            {select === index ? (
                                <img loading="lazy" src={`${file_cdn}${item.leftIcon}`} alt="" className={styles['left-icon']} />
                            ) : (
                                <div className={styles['left-icon']} />
                            )}
                            <div className={styles.text}>
                                <div className={styles.t}>{intl.get(item.title)}</div>
                                {select === index && <div className={classnames(styles.d, styles[`d-${currentLocale}`])}>{intl.get(item.desc)}</div>}
                            </div>
                            <img loading="lazy" src={`${file_cdn}${item.rightIcon}`} alt="" className={styles['right-icon']} />
                        </div>
                    ))}
                </div>
                <div className={styles.right}>
                    <Carousel dots={false} ref={tabRef} adaptiveHeight dotPosition="left">
                        {data.map((item) => (
                            <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" key={item.key} />
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default ServiceAdvantages;
