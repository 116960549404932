import React from 'react';
import intl from 'react-intl-universal';
import { LayoutCenter } from '@components/Services';
import { MTitlecom } from '@components/MCommon';
import classNames from 'classnames';
import style from './index.module.less';

interface Iprops {
    currentLocale: any;
    title: string;
    titleDesc: string;
    label: string;
    accountList: { title: string }[];
    bankList: { img: string }[];
}

const MSetUpAccount: React.FC<any> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const lang = (intl as any).options.currentLocale;
    const { currentLocale = lang, title, titleDesc, label, accountList, bankList } = props;
    return (
        <>
            <LayoutCenter style={{ margin: '72px auto 16px' }}>
                <MTitlecom title={title} descContent={titleDesc} label={label} type="center" />
            </LayoutCenter>
            <div className={style.container}>
                <div className={classNames(style['account-list'], style[currentLocale])}>
                    {accountList.map((item) => (
                        <div className={classNames(style['account-item'], style[currentLocale])} key={intl.get(item.title)}>
                            <div className={classNames(style['tit-btn'], style[currentLocale])}>{intl.get(item.title)}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={style['bank-list-con']}>
                <div className={style['bank-list']}>
                    {bankList.map((item) => (
                        <div className={style['bank-item']} key={item.img}>
                            <img loading="lazy" className={style['bank-img']} src={`${file_cdn}${item.img}`} alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MSetUpAccount;
