import React, { useRef, useState } from 'react';
import { Titlecom } from '@components/Common';
import { RightSuperIcon } from '@components/Services';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Carousel } from 'antd';
import styles from './style.module.less';

interface IProps {
    data: Array<{
        key: string;
        title: string;
        desc: string;
        subTitle: string;
        subTitle2: string;
        content: Array<{ key: string; desc: string }>;
        img: string;
    }>;
}
const ProjectIntro: React.FC<IProps> = ({ data }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    const goClick = async (type: string) => {
        switch (type) {
            case 'up':
                tabRef.current && tabRef.current.goTo(0);
                setActIndex(0);
                break;
            case 'bottom':
                tabRef.current && tabRef.current.goTo(1);
                setActIndex(1);
                break;
        }
    };

    return (
        <div lang={currentLocale} className={styles['project-intro']}>
            <RightSuperIcon />
            <div className={styles['c-p']}>
                <Carousel dots={false} ref={tabRef} adaptiveHeight dotPosition="left">
                    {data.map((item) => (
                        <div className={styles.project} key={item.key}>
                            <Titlecom title={intl.get(item.title)} descContent={item.desc} />
                            <div className={styles.trq}>
                                <div className={styles.left}>
                                    <div className={styles.title}>
                                        {intl.get(item.subTitle)}
                                        <span>{intl.get(item.subTitle2)}</span>
                                    </div>
                                    {item.content.map((cItem) => (
                                        <div className={styles.content} key={cItem.key}>
                                            {intl.get(cItem.desc)}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.right}>
                                    <div className={styles['arrow-left']}>
                                        <span
                                            className={classnames(styles.arrow, actIndex === 0 && styles['arrow-active'])}
                                            onClick={() => goClick('up')}
                                        >
                                            ∧
                                        </span>
                                        <span
                                            className={classnames(styles.arrow, actIndex === 1 && styles['arrow-active'])}
                                            onClick={() => goClick('bottom')}
                                        >
                                            ∨
                                        </span>
                                    </div>
                                    <img loading="lazy" src={`${file_cdn}${item.img}`} alt="" className={styles['arrow-img']} />
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default ProjectIntro;
