import React from 'react';
import intl from 'react-intl-universal';
import MTitlecom from '../../../MCommon/MTitlecom';
import styles from './style.module.less';

interface IProps {
    title: string;
    descContent: any;
    type: 'center' | 'left' | 'right';
    contentEnglish: string;
    contentTitle: string;
    contentDesc1: string;
    contentDesc2: string;
    style?: any;
}
const MEntrustedChina: React.FC<IProps> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const { title, descContent, type, contentEnglish, contentTitle, contentDesc1, contentDesc2, style } = props;
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-entrusted-china']} style={{ ...style }}>
            <div className={styles.title}>
                <MTitlecom title={title} descContent={descContent} type={type} />
            </div>
            <div className={styles.desc}>
                <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/m/china-bg.svg`} alt="" className={styles['img-bg']} />
                <div className={styles.container}>
                    <div className={styles.left}>
                        {contentEnglish}
                        <div className={styles.line} />
                        <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/m/china1.jpg`} alt="" className={styles['img-china1']} />
                    </div>
                    <img loading="lazy" src={`${file_cdn}/static/services/chinanotary/m/china2.jpg`} alt="" className={styles.right} />
                </div>
            </div>
            <div className={styles['text-title']}>{contentTitle}</div>
            <p className={styles['text-p']}>{contentDesc1}</p>
            <p className={styles['text-p']}>{contentDesc2}</p>
        </div>
    );
};

MEntrustedChina.defaultProps = {
    style: {}
};

export default MEntrustedChina;
