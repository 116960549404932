import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import style from './index.module.less';

interface IProps {
    title: React.ReactNode;
    descContent: string;
    coverImg: string;
    isMobile?: boolean;
}

const ProjectIntroduce: React.FC<IProps> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const { title, descContent, coverImg, isMobile } = props;
    const descRef = useRef(null);
    const imgRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);

    const handleScroll = () => {
        setScrollHeight(window.scrollY);
    };

    useEffect(() => {
        const thresholdHeight = 1580;

        window.addEventListener('scroll', handleScroll);

        if (scrollHeight >= thresholdHeight) {
            window.removeEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollHeight]);

    return (
        <>
            {isMobile ? (
                <div className={style['m-container']}>
                    <em className={style.cover} style={{ backgroundImage: `url(${file_cdn}${coverImg})` }} />
                    <div className={style.title} style={{ backgroundImage: `url('${file_cdn}/static/services/hongkongzc/pc/punctuation-bg.png')` }}>
                        {title}
                    </div>
                    <p className={style.desc} dangerouslySetInnerHTML={{ __html: descContent }} />
                </div>
            ) : (
                <div ref={descRef} className={style.container}>
                    <div
                        className={classNames(style['desc-container'], {
                            [style['desc-container-animation']]: scrollHeight >= 1580
                        })}
                    >
                        <em
                            className={style['title-bg']}
                            style={{ backgroundImage: `url('${file_cdn}/static/services/hongkongzc/pc/punctuation-bg.png')` }}
                        />
                        <div className={style.title}>{title}</div>
                        <p className={style.desc} dangerouslySetInnerHTML={{ __html: descContent }} />
                        <em className={style['line-left']} />
                    </div>
                    <img
                        loading="lazy"
                        ref={imgRef}
                        src={`${file_cdn}${coverImg}`}
                        className={classNames(style['desc-img'], {
                            [style['desc-img-animation']]: scrollHeight >= 1580
                        })}
                        alt=""
                    />
                </div>
            )}
        </>
    );
};

ProjectIntroduce.defaultProps = {
    isMobile: false
};

export default ProjectIntroduce;
