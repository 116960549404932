import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Carousel } from 'antd';
import intl from 'react-intl-universal';
import styles from './style.module.less';
import MTitlecom from '../../MTitlecom';

interface IProps {
    type: 'center' | 'left' | 'right';
    isTabs?: boolean;
    data: { title: string; desc: string; tabData: { key: string; title: string; children: { title: string; desc: string; img: string }[] }[] };
}
const MCarouselCut: React.FC<IProps> = ({ data, type, isTabs }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const tabRef = useRef<any>(null);
    const [actIndex, setActIndex] = useState<number>(0);

    return (
        <div lang={currentLocale} className={styles['m-carousel-cut']}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title) || data.title} descContent={data.desc} type={type} />
            </div>

            <div className={styles.container}>
                {isTabs && (
                    <div className={styles.cut}>
                        {data.tabData.map((item, index) => (
                            <div
                                key={item.key}
                                className={classNames(styles.pot, actIndex === index && styles['pot-active'])}
                                onClick={() => {
                                    tabRef.current && tabRef.current.goTo(index);
                                    setActIndex(index);
                                }}
                            >
                                <div className={styles.bg} />
                                <img
                                    loading="lazy"
                                    src={`${file_cdn}/static/services/turkeyym/pc/${actIndex === index ? 'right' : 'hui'}.svg`}
                                    alt=""
                                    className={styles['bg-icon']}
                                />
                                <div className={styles['cut-title']}>{intl.get(item.title) || item.title}</div>
                                <div className={classNames(styles.line, actIndex === index && styles['line-active'])} />
                            </div>
                        ))}
                    </div>
                )}

                <Carousel
                    dots={false}
                    ref={tabRef}
                    adaptiveHeight
                    beforeChange={(currentIdx, currentIdx2) => {
                        setActIndex(currentIdx2);
                    }}
                >
                    {data.tabData.map((item) => (
                        <div key={item.key} className={styles['container-line']}>
                            <span className={styles.line} />
                            {item.children.map((itemC, indexC) => (
                                <div className={styles.container} key={itemC.title}>
                                    <div className={styles.left}>
                                        <img loading="lazy" src={`${file_cdn}${itemC.img}`} alt="" className={styles.img} />
                                        {indexC + 1 !== item.children.length && (
                                            <img
                                                loading="lazy"
                                                src={`${file_cdn}/static/services/accounting/m/icon/arrow.svg`}
                                                alt=""
                                                className={styles.arrow}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.right}>
                                        <div className={styles.tit}>
                                            <span>{`0${indexC + 1}`}</span>
                                            {(itemC.title && intl.get(itemC.title)) || itemC.title}
                                        </div>
                                        <div className={styles.desc}>{(itemC.desc && intl.get(itemC.desc)) || itemC.desc}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

MCarouselCut.defaultProps = { isTabs: true };

export default MCarouselCut;
