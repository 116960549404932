import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import styles from './style.module.less';
import MTitlecom from '../../MTitlecom';

interface IProps {
    type: 'center' | 'left' | 'right';
    data: { title: string; desc: string; icon: string; bg: string; bg2: string; children: { title: string; desc: string[] }[] };
}
const MMainApply: React.FC<IProps> = ({ data, type }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={styles['m-main-apply']}>
            <div className={styles.title}>
                <MTitlecom title={intl.get(data.title) || data.title} descContent={data.desc} type={type} />
            </div>

            <div className={styles.container}>
                {data.children.map((item) => (
                    <div className={styles.row} key={item.title}>
                        <div className={styles.title}>
                            <div className={styles.base} />
                            <div className={styles['title-text']}>{intl.get(item.title) || item.title}</div>
                        </div>
                        {item.desc.map((itemD) => (
                            <div className={styles.text} key={itemD}>
                                <img loading="lazy" src={`${file_cdn}/static/services/turkeyym/pc/arrow.svg`} alt="" className={styles.img} />
                                <img
                                    loading="lazy"
                                    src={`${file_cdn}/static/services/turkeyym/pc/arrow.svg`}
                                    alt=""
                                    className={classNames(styles.img, styles.img2)}
                                />
                                {intl.get(itemD) || itemD}
                            </div>
                        ))}
                    </div>
                ))}
                <div className={styles.bg}>
                    <img loading="lazy" src={`${file_cdn}${data.icon}`} alt="" className={styles.arrow} />
                    <img loading="lazy" src={`${file_cdn}${data.bg}`} alt="" className={styles['bg-img']} />
                    <img loading="lazy" src={`${file_cdn}${data.bg2}`} alt="" className={styles['bg-img2']} />
                </div>
            </div>
        </div>
    );
};

MMainApply.defaultProps = {};

export default MMainApply;
